import { sendAnalyticsEvent } from 'dpl/util/analytics';
import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import { isMobileUA } from 'dpl/shared/utils';
import { BUYER_MESSAGES_PATH } from 'dpl/shared/constants/urls';

export async function initGladlySidekick() {
  const IS_MOBILE = isMobileUA();

  const skipLoading =
    IS_MOBILE && window.location.pathname.includes(BUYER_MESSAGES_PATH);

  if ('Gladly' in window && !skipLoading) {
    await window.Gladly.init({
      autoShowButton: true,
      appId: window.__DPL_GLADLY_SIDEKICK
    });

    window.Gladly.on('sidekick:opened', () => {
      sendAnalyticsEvent(ANALYTICS_EVENTS.GLADLY_SIDEKICK_OPENED);
    });
    window.Gladly.on('sidekick:closed', () => {
      sendAnalyticsEvent(ANALYTICS_EVENTS.GLADLY_SIDEKICK_CLOSED);
    });
    window.Gladly.on('quick-action:selected', quickAction => {
      sendAnalyticsEvent(
        ANALYTICS_EVENTS.GLADLY_SIDEKICK_QUICK_ACTION_SELECTED,
        {
          quick_action: quickAction[0].label
        }
      );
    });
    window.Gladly.on('search:result-selected', searchResult => {
      sendAnalyticsEvent(
        ANALYTICS_EVENTS.GLADLY_SIDEKICK_SEARCH_RESULT_SELECTED,
        {
          search_result: searchResult[0].label
        }
      );
    });
  }
}
