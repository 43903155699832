/**
 * NOTE: This file is meant only for scripts that need to run only once
 */

import { isMobileSafari } from 'dpl/shared/utils';
import { initGladlySidekick } from 'dpl/util/gladlySidekick';

function bindKeyboardOutlineHandler() {
  function handleMouseDown() {
    window.removeEventListener('mousedown', handleMouseDown);
    document.body.classList.add('no-outline');
    bindKeyboardOutlineHandler();
  }

  function handleKeyDown(e) {
    // user is using keyboard
    if (e.key === 'Tab') {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('no-outline');
      window.addEventListener('mousedown', handleMouseDown);
    }
  }
  window.addEventListener('keydown', handleKeyDown);
}

// iOS includes navbars in 100 vh
function overrideViewHeightCssRules() {
  const el = document.createElement('style');
  const height = `${parseInt(window.innerHeight, 10)}px`;
  el.innerHTML = `
    .vh-100 { height: ${height} !important; }
    .min-vh-100 { min-height: ${height} !important; }
  `;
  document.head.appendChild(el);
}

document.addEventListener('DOMContentLoaded', () => {
  bindKeyboardOutlineHandler();

  if (isMobileSafari()) {
    overrideViewHeightCssRules();
  }
});

initGladlySidekick();
